import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const NavbarNL = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <button className="navbar-toggle" onClick={toggleMenu}>
          <span className="hamburger-icon">&#9776;</span>
        </button>
      </div>

      <ul className={`navbar-list ${isOpen ? "open" : ""}`}>
        <li className="navbar-item">
          <Link to="/home" onClick={toggleMenu}>
            Home
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/products" onClick={toggleMenu}>
            Shop
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/contact" onClick={toggleMenu}>
            Contact
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/about" onClick={toggleMenu}>
            About
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavbarNL;
