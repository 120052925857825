import React, { useState, useEffect } from 'react';
import { firestore, collection, getDocs, doc, updateDoc } from '../../firebase'; // Ensure firebase.js is correctly imported
import { storage, ref, uploadBytes, getDownloadURL, addDoc } from '../../firebase'; // Ensure firebase.js is correctly imported
import './DisplayManager.css'; // You can create this CSS file for custom styles

function DisplayManager() {
  const [displayPhotos, setDisplayPhotos] = useState([]);
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  //eslint-disable-next-line
  const [visibleFilter, setVisibleFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page

  // Add display form states
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null); // File object
  const [visible, setVisible] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedPhotoId, setSelectedPhotoId] = useState(null); // Track the selected photo's ID for updates
  const [existingImageUrl, setExistingImageUrl] = useState(''); // Store the existing image URL for update

  // Fetch display photos from Firestore
  useEffect(() => {
    const fetchDisplayPhotos = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'display-photos'));
        const photos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDisplayPhotos(photos);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching display photos:', error);
        setLoading(false);
      }
    };

    fetchDisplayPhotos();
  }, []);

  // Filter the photos based on visibility filter
  useEffect(() => {
    const filtered = visibleFilter === 'all'
      ? displayPhotos
      : displayPhotos.filter(photo => photo.visible === (visibleFilter === 'visible'));
    
    setFilteredPhotos(filtered);
    setCurrentPage(1); // Reset to first page whenever the filter changes
  }, [displayPhotos, visibleFilter]);

  // Pagination logic
  const totalPages = Math.ceil(filteredPhotos.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  //eslint-disable-next-line
  const currentPhotos = filteredPhotos.slice(startIndex, startIndex + itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  // Handle visibility toggle
  const handleVisibilityChange = async (id, newVisibleStatus) => {
    try {
      const photoRef = doc(firestore, 'display-photos', id);
      await updateDoc(photoRef, { visible: newVisibleStatus });
      setDisplayPhotos(prevPhotos =>
        prevPhotos.map(photo =>
          photo.id === id ? { ...photo, visible: newVisibleStatus } : photo
        )
      );
    } catch (error) {
      console.error('Error updating visibility:', error);
    }
  };

  // Handle file input for the add form
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  // Handle form submission to add a new display photo
  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    try {
      let imageUrl = '';
      if (image) {
        const imageRef = ref(storage, `display-photos/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef);
      }

      const displayPhoto = {
        name,
        description,
        imageUrl,
        visible,
      };

      await addDoc(collection(firestore, 'display-photos'), displayPhoto);

      setName('');
      setDescription('');
      setImage(null);
      setVisible(true);
      setUploading(false);
      setSuccessMessage('Display photo added successfully!');
      setErrorMessage('');

      // Refresh the list after adding a new photo
      const querySnapshot = await getDocs(collection(firestore, 'display-photos'));
      const updatedPhotos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDisplayPhotos(updatedPhotos);
    } catch (error) {
      console.error('Error adding display photo:', error);
      setErrorMessage('There was an error adding the display photo. Please try again.');
      setUploading(false);
    }
  };

  // Handle updating an existing display photo
  const handleSave = async (e) => {
    e.preventDefault();
    if (!selectedPhotoId) return; // Do nothing if no photo is selected for editing

    setUploading(true);

    try {
      // If a new image is selected, upload and get its URL
      let imageUrl = existingImageUrl; // Use the existing image URL by default
      if (image) {
        const imageRef = ref(storage, `display-photos/${image.name}`);
        await uploadBytes(imageRef, image);
        imageUrl = await getDownloadURL(imageRef); // Get the new image URL
      }

      const photoRef = doc(firestore, 'display-photos', selectedPhotoId);
      const updatedPhoto = {
        name,
        description,
        imageUrl, // Use either the existing or new image URL
        visible,
      };

      await updateDoc(photoRef, updatedPhoto);

      setUploading(false);
      setSuccessMessage('Display photo updated successfully!');
      setErrorMessage('');

      // Reset form after save
      setName('');
      setDescription('');
      setImage(null);
      setVisible(true);
      setSelectedPhotoId(null);
      setExistingImageUrl(''); // Reset existing image URL

      // Refresh the list after saving
      const querySnapshot = await getDocs(collection(firestore, 'display-photos'));
      const updatedPhotos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDisplayPhotos(updatedPhotos);
    } catch (error) {
      console.error('Error updating display photo:', error);
      setErrorMessage('There was an error updating the display photo. Please try again.');
      setUploading(false);
    }
  };

  // Populate the form with the selected photo data
  const handleRowClick = (photo) => {
    setName(photo.name);
    setDescription(photo.description);
    setVisible(photo.visible);
    setExistingImageUrl(photo.imageUrl); // Set the existing image URL for the update
    setSelectedPhotoId(photo.id); // Track the selected photo's ID for updates
  };

  // Clear the form
  const handleClearForm = () => {
    setName('');
    setDescription('');
    setImage(null);
    setVisible(true);
    setSelectedPhotoId(null);
    setExistingImageUrl(''); // Clear existing image URL
  };

  return (
    <div className="display-manager-container">
      <h2>Display Manager</h2>
      <div className="form-and-list">
        {/* Add Display Photo Form */}
        <div className="add-display-form">
          <h3>{selectedPhotoId ? 'Edit Display Photo' : 'Add Display Photo'}</h3>
          <form onSubmit={selectedPhotoId ? handleSave : handleSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="description">Description:</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="image">Image:</label>
              <input
                type="file"
                id="image"
                onChange={handleFileChange}
              />
            </div>
            <div>
              <label htmlFor="visible">Visible:</label>
              <input
                type="checkbox"
                id="visible"
                checked={visible}
                onChange={(e) => setVisible(e.target.checked)}
              />
            </div>
            <button type="submit" disabled={uploading}>
              {uploading ? 'Saving...' : selectedPhotoId ? 'Save Changes' : 'Add Photo'}
            </button>
            <button type="button" onClick={handleClearForm}>
              Clear Form
            </button>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
        </div>

        {/* Display Photos List */}
        <div className="display-list">
          <h3>Display Photos</h3>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Description</th>
                  <th>Visible</th>
                </tr>
              </thead>
              <tbody>
                {filteredPhotos.map(photo => (
                  <tr key={photo.id} onClick={() => handleRowClick(photo)}>
                    <td><img src={photo.imageUrl} alt={photo.name} style={{ width: '100px' }} /></td>
                    <td>{photo.description}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={photo.visible}
                        onChange={(e) => handleVisibilityChange(photo.id, e.target.checked)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              style={{ marginRight: '10px' }}
            >
              Previous
            </button>
            <p>Page {currentPage} of {totalPages}</p>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              style={{ marginLeft: '10px' }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplayManager;
