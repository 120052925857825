import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import "./PhotosDisplay.css";
// import PhotosDisplay from "./PhotosDisplay";

const About = () => {
  //eslint-disable-next-line
  const [photos, setPhotos] = useState([]);
  
  //eslint-disable-next-line
  const [isEnglish, setIsEnglish] = useState(true);

  // Fetch photos from Firestore
  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'display-photos'));
        const photosArray = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        const visiblePhotos = photosArray.filter(photo => photo.visible === true);
        setPhotos(visiblePhotos);
      } catch (error) {
        console.error('Error fetching photos: ', error);
      }
    };

    fetchPhotos();
  }, []);

  // Inline styles for the component
  const pageStyle = {
    textAlign: "center",
    padding: "20px",
    fontFamily: '"Arial", sans-serif',
  };

  const headingStyle = {
    color: "#333",
    fontSize: "2.5em",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    color: "#333",
    fontSize: "1.3em",
    marginBottom: "20px",
  };

  const carouselContainerStyle = {
    margin: "0 auto", // Center carousel
    maxWidth: "80%", // Limit the carousel's width
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add subtle shadow for separation
    borderRadius: "10px", // Rounded corners for the entire carousel container
    overflow: "hidden", // Prevents overflow from rounded corners
  };

  // const carouselImageStyle = {
  //   width: "100%",
  //   maxHeight: "70vh", // Constrain the image height relative to the viewport
  //   objectFit: "contain", // Contain the image within the frame without cutting it off
  // };

  // const carouselOverlayStyle = {
  //   position: "absolute",
  //   bottom: "10px",
  //   left: "0",
  //   width: "100%",
  //   background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
  //   color: "white",
  //   textAlign: "center",
  //   padding: "10px 0",
  // };

  // const carouselItemStyle = {
  //   position: "relative",
  // };

  // const switchStyle = {
  //   margin: "20px auto",
  //   display: "inline-block",
  //   padding: "10px 20px",
  //   border: "1px solid #ccc",
  //   borderRadius: "20px",
  //   backgroundColor: isEnglish ? "#093112" : "#333",
  //   color: "white",
  //   cursor: "pointer",
  // };

  const kevPhoto = {
    width: "50%",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "20px auto",
  };

  
  // const shedRightUrl = "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Fshed-right.jpg?alt=media&token=7ca9b990-0f37-471b-be40-4013d9288b6e";

  // const shedLeftUrl = "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Fshed-left.jpg?alt=media&token=028f11a8-7fb4-4aae-ad4b-adaea8188adf";

  const kev = "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Fkev.jpg?alt=media&token=907ec5f5-7a4b-4770-85e1-b56d1e6e88d9";

  const shedMidUrl = "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Fshed-mid.jpg?alt=media&token=d6d7a1ba-7f51-4153-af5e-9399ed20a83b";

  const aboutTxt = "From The Shed Woodworks is a small woodworking shop in Hanwell NB specializing in functional home décor products.   After almost 30 years (and counting) spent working in an agricultural sector, it was time for new interests and challenges.  So, a few years ago, the backyard shed received an expansion (and a wood stove).  Gradually the shed began a transition from storage space to a woodworking shop. "
  const aboutTxt2 = "In early 2024, “From The Shed Woodworks” started offering a variety of cutting boards, charcuterie boards and coasters for sale with the aim of expanding into other product lines.  Every single piece that comes out of the shed is individually hand crafted and unique; no two are the same. The aim of From the Shed Woodworks is to provide customers with a wide variety of unique, high quality and affordable home décor items that anybody would be proud to use and display in their homes."

  return (
    <div style={pageStyle}>
      <h1 style={headingStyle}>About Us</h1>
      {/* <p style={paragraphStyle}>
        We specialize in high-quality woodworking
        products, each crafted with care and attention to detail.
      </p> */}

      <img src={shedMidUrl} alt="The Shed" className="shed-photo" />


        
        <div style={paragraphStyle}>
        {aboutTxt}
        <br/>
        <br/>

        {/* <hr/> */}
        {aboutTxt2}
      </div>
     

      {/* Carousel Section */}
      <div style={carouselContainerStyle}>
      {/* <PhotosDisplay /> */}
      </div>

    {/* photo of kev, the proud owner operator of the business */}

    <img src={kev} alt="Kev" style={kevPhoto}/>


    </div>
  );
};

export default About;
