import React, { useState, useEffect } from 'react';
import { firestore } from '../../firebase'; // Ensure this is the correct import for your Firebase config
import { collection, getDocs, doc, getDoc, updateDoc, query, orderBy } from 'firebase/firestore';
import './AdminProdInquiries.css';

function AdminProdInquiries() {
  const [productInquiries, setProductInquiries] = useState([]);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductInquiries = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(firestore, 'prod-inquiries'), orderBy('timestamp', 'desc'))
        );

        const inquiries = await Promise.all(
          querySnapshot.docs.map(async (docSnapshot) => {
            const inquiry = docSnapshot.data();
            inquiry.id = docSnapshot.id; // Include the document ID

            // console.log('Inquiry ID:', inquiry.id, 'Product ID:', inquiry.productId);

            // Fetch product details using the productId as the document name
            const productRef = doc(firestore, 'products-test', inquiry.productId);
            const productDoc = await getDoc(productRef);

            if (productDoc.exists()) {
              inquiry.quotedPrice = inquiry.quotedPrice || productDoc.data().price;
              inquiry.productName = productDoc.data().name; // Include product name
            } else {
              console.error('No product found for Product ID:', inquiry.productId);
              inquiry.productName = 'Unknown Product';
            }

            return inquiry;
          })
        );

        // Sort inquiries to show unresponded first
        inquiries.sort((a, b) => {
          const timestampA = a.timestamp ? a.timestamp.toDate() : new Date(0);
          const timestampB = b.timestamp ? b.timestamp.toDate() : new Date(0);

          if (a.responded === b.responded) {
            return timestampB - timestampA; // Most recent first
          }
          return a.responded ? 1 : -1; // Unresponded first
        });

        setProductInquiries(inquiries);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product inquiries:', error);
      }
    };

    fetchProductInquiries();
  }, []);

  const handleInputChange = (field, value) => {
    setSelectedInquiry((prevInquiry) => ({
      ...prevInquiry,
      [field]: value,
    }));
  };

  const handleSave = async () => {
    if (!selectedInquiry) return;
    try {
      const inquiryRef = doc(firestore, 'prod-inquiries', selectedInquiry.id);
      await updateDoc(inquiryRef, selectedInquiry);
      console.log('Inquiry updated successfully');

      // Update local state
      setProductInquiries((prevInquiries) =>
        prevInquiries.map((inquiry) =>
          inquiry.id === selectedInquiry.id ? selectedInquiry : inquiry
        )
      );
    } catch (error) {
      console.error('Error updating inquiry:', error);
    }
  };

  return (
    <div className="admin-prod-inquiries">
      <h2>Product Inquiries</h2>
      <div className="content">
        {/* Table Section */}
        <div className="inquiries-table">
          {loading ? (
            <p>Loading inquiries...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Message</th>
                  <th>Product ID</th>
                  <th>Quoted Price</th>
                  <th>Responded</th>
                  <th>Sold</th>
                  <th>Sold Price</th>
                  <th>Timestamp</th>
                </tr>
              </thead>
              <tbody>
                {productInquiries.map((inquiry) => (
                  <tr
                    key={inquiry.id}
                    onClick={() => setSelectedInquiry(inquiry)}
                    className={selectedInquiry?.id === inquiry.id ? 'selected' : ''}
                  >
                    <td>{inquiry.email}</td>
                    <td>{inquiry.message}</td>
                    <td>
                      {inquiry.productName !== 'Unknown Product' ? (
                        <a href={`/products/${inquiry.productId}`} target="_blank" rel="noopener noreferrer">
                          {inquiry.productId}
                        </a>
                      ) : (
                        'Unknown Product'
                      )}
                    </td>
                    <td>{inquiry.quotedPrice}</td>
                    <td>{inquiry.responded ? 'Yes' : 'No'}</td>
                    <td>{inquiry.sold ? 'Yes' : 'No'}</td>
                    <td>{inquiry.soldPrice || 'N/A'}</td>
                    <td>
                      {inquiry.timestamp
                        ? inquiry.timestamp.toDate().toLocaleString()
                        : 'N/A'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        {/* Form Section */}
        {selectedInquiry && (
          <div className="inquiry-form">
            <h3>Edit Inquiry</h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <div>
                <label>Email:</label>
                <input type="text" value={selectedInquiry.email} readOnly />
              </div>
              <div>
                <label>Message:</label>
                <textarea
                  value={selectedInquiry.message}
                  onChange={(e) => handleInputChange('message', e.target.value)}
                />
              </div>
              <div>
                <label>Quoted Price:</label>
                <input
                  type="number"
                  value={selectedInquiry.quotedPrice || ''}
                  onChange={(e) => handleInputChange('quotedPrice', e.target.value)}
                />
              </div>
              <div>
                <label>Sold Price:</label>
                <input
                  type="number"
                  value={selectedInquiry.soldPrice || ''}
                  onChange={(e) => handleInputChange('soldPrice', e.target.value)}
                />
              </div>
              <div>
                <label>Responded:</label>
                <select
                  value={selectedInquiry.responded ? 'Yes' : 'No'}
                  onChange={(e) => handleInputChange('responded', e.target.value === 'Yes')}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div>
                <label>Sold:</label>
                <select
                  value={selectedInquiry.sold ? 'Yes' : 'No'}
                  onChange={(e) => handleInputChange('sold', e.target.value === 'Yes')}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <button onClick={handleSave}>Save</button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminProdInquiries;
