/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./App.css";
import "./styles.css";
import AppRoutes from "./AppRoutes";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false); // Track if the user is an admin
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [showNavbar, setShowNavbar] = useState(true); // State to show or hide navbar

  useEffect(() => {
    const checkAuth = () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        setIsAuthenticated(true);
        setRole(user.role);
        setUser(user);
        if (user.role === "admin") {
          setIsAdmin(true);
        }
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
        setUser(null);
      }
    };

    checkAuth();
  }, []);

  return (
    <Router>
      <div>
        {/* Conditionally render Navbar */}
        {showNavbar && (
          <Navbar />
        )}

        <AppRoutes 
          user={user}
          setShowNavbar={setShowNavbar} // Pass down to control navbar visibility
        />

        <Footer />
      </div>
    </Router>
  );
};

export default App;

