import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <p>&copy; {new Date().getFullYear()} From the Shed</p>
          <p>Hanwell, New Brunswick</p> {/* Update with actual address */}
        </div>
        <div className="footer-section">
          {/* <h4>Location</h4> */}
        </div>
        <div className="footer-section">
          {/* <h4>Contact Us</h4> */}
          {/* <p><strong>Support:</strong> support@fromtheshed.com</p> */}
          {/* <p><strong>Sales:</strong> sales@fromtheshed.com</p> */}
          <p> info@fromtheshed.ca</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
