import React, { useState } from 'react';
import './AdminNav.css';
import ProductManager from './ProductManager';  // Import the ProductManager component
import TypeManager from './TypeManager';        // Import the TypeManager component
import DisplayManager from './DisplayManager';  // Import the DisplayManager component
import AdminProdInquiries from './AdminProdInquiries';
import AdminGeneralInquiries from './AdminGenInquiries';

const AdminNav = () => {
  const [isProductsCollapsed, setIsProductsCollapsed] = useState(true);
  const [isProductTypesCollapsed, setIsProductTypesCollapsed] = useState(true);
  const [isDisplaysCollapsed, setIsDisplaysCollapsed] = useState(true);
  const [isInquiriesCollapsed, setIsInquiriesCollapsed] = useState(true);

  return (
    <nav className="admin-nav">
      <ul>
        {/* Products Section */}
        <h3 className="nav-section" onClick={() => setIsProductsCollapsed(!isProductsCollapsed)}>
          Products
          <span className="collapse-icon">{isProductsCollapsed ? '▼' : '▲'}</span>
        </h3>
        <ul style={{ display: isProductsCollapsed ? 'none' : 'block' }}>
          <li>
            <ProductManager /> {/* Render ProductManager component */}
          </li>
          {/* <li><a href="/admin/products/add">Add Product</a></li>
          <li><a href="/admin/products">Product List</a></li> */}
        </ul>

        {/* Product Types Section */}
        <h3 className="nav-section" onClick={() => setIsProductTypesCollapsed(!isProductTypesCollapsed)}>
          Product Types
          <span className="collapse-icon">{isProductTypesCollapsed ? '▼' : '▲'}</span>
        </h3>
        <ul style={{ display: isProductTypesCollapsed ? 'none' : 'block' }}>
          <li>
            <TypeManager /> {/* Render TypeManager component */}
          </li>
          {/* <li><a href="/admin/product-type/add">Add Product Type</a></li>
          <li><a href="/admin/product-type">Product Type List</a></li> */}
        </ul>

        {/* Displays Section */}
        <h3 className="nav-section" onClick={() => setIsDisplaysCollapsed(!isDisplaysCollapsed)}>
          Displays
          <span className="collapse-icon">{isDisplaysCollapsed ? '▼' : '▲'}</span>
        </h3>
        <ul style={{ display: isDisplaysCollapsed ? 'none' : 'block' }}>
          <li>
            <DisplayManager /> {/* Render DisplayManager component */}
          </li>
          {/* <li><a href="/admin/display/add">Add Display</a></li>
          <li><a href="/admin/display">Display List</a></li> */}
        </ul>

        {/* Inquiries Section */}
        <h3 className="nav-section" onClick={() => setIsInquiriesCollapsed(!isInquiriesCollapsed)}>
          Inquiries
          <span className="collapse-icon">{isInquiriesCollapsed ? '▼' : '▲'}</span>
        </h3>
        <ul style={{ display: isInquiriesCollapsed ? 'none' : 'block' }}>
          <li><a href="/admin/product-inquiries">Product Inquiries</a></li>
          <li><a href="/admin/general-inquiries">General Inquiries</a></li>
        </ul>

        <h3 className='nav-section' onClick={() => setIsInquiriesCollapsed(!isInquiriesCollapsed)}>
          Product Inquiries
          <span className='collapse-icon'>{isInquiriesCollapsed ? '▼' : '▲'}</span>
        </h3>
        <ul style={{ display: isInquiriesCollapsed ? 'none' : 'block' }}>
          <AdminProdInquiries />
          </ul>


          <h3 className='nav-section' onClick={() => setIsInquiriesCollapsed(!isInquiriesCollapsed)}>
          General Inquiries
          <span className='collapse-icon'>{isInquiriesCollapsed ? '▼' : '▲'}</span>
        </h3>
        <ul style={{ display: isInquiriesCollapsed ? 'none' : 'block' }}>
          <AdminGeneralInquiries />
          </ul>

        
      </ul>
    </nav>
  );
};

export default AdminNav;
