import React, { useState, useEffect } from 'react';
import { firestore, collection, getDocs, updateDoc, doc } from '../../firebase'; // Ensure firebase.js is correctly imported

function AdminDisplayList() {
  const [displayPhotos, setDisplayPhotos] = useState([]);
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [visibleFilter, setVisibleFilter] = useState('all');
  const [loading, setLoading] = useState(true);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page

  // Fetch display photos from Firestore
  useEffect(() => {

    
    const checkLocalStorage = () => {
      const localStorageData = localStorage.getItem('user');
      if (localStorageData) {
        // console.log('Local Storage Data:', localStorageData);
      } else {
        // console.log('No data found in Local Storage for key: someKey');
      }
      if (!localStorageData) {
        window.location.href = '/';
      }
    };

    checkLocalStorage();
    const fetchDisplayPhotos = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'display-photos'));
        const photos = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDisplayPhotos(photos);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching display photos:', error);
        setLoading(false);
      }
    };

    fetchDisplayPhotos();
  }, []);

  // Filter the photos based on visibility filter
  useEffect(() => {
    const filtered = visibleFilter === 'all'
      ? displayPhotos
      : displayPhotos.filter(photo => photo.visible === (visibleFilter === 'visible'));
    
    setFilteredPhotos(filtered);
    setCurrentPage(1); // Reset to first page whenever the filter changes
  }, [displayPhotos, visibleFilter]);

  // Pagination logic
  const totalPages = Math.ceil(filteredPhotos.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentPhotos = filteredPhotos.slice(startIndex, startIndex + itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  // Handle visibility toggle
  const handleVisibilityChange = async (id, newVisibleStatus) => {
    try {
      // Reference to the specific photo document in Firestore
      const photoRef = doc(firestore, 'display-photos', id);
      
      // Update the 'visible' field in Firestore
      await updateDoc(photoRef, { visible: newVisibleStatus });
      
      // Update the local state to reflect the change
      setDisplayPhotos(prevPhotos =>
        prevPhotos.map(photo =>
          photo.id === id ? { ...photo, visible: newVisibleStatus } : photo
        )
      );
    } catch (error) {
      console.error('Error updating visibility:', error);
    }
  };

  return (
    <div className="admin-display-list">
      <h2>Display Photos</h2>
      <div>
        <label>Filter by Visibility:</label>
        <select onChange={(e) => setVisibleFilter(e.target.value)} value={visibleFilter}>
          <option value="all">All</option>
          <option value="visible">Visible</option>
          <option value="hidden">Hidden</option>
        </select>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {filteredPhotos.length === 0 ? (
            <p>No display photos available</p>
          ) : (
            <>
              <ul>
                {currentPhotos.map(photo => (
                  <li key={photo.id}>
                    <h3>{photo.name}</h3>
                    <p>{photo.description}</p>
                    <img src={photo.imageUrl} alt={photo.name} style={{ width: '150px', height: '150px' }} />
                    <p>Status: {photo.visible ? 'Visible' : 'Hidden'}</p>
                    
                    {/* Visibility checkbox */}
                    <label>
                      Visible:
                      <input
                        type="checkbox"
                        checked={photo.visible}
                        onChange={(e) => handleVisibilityChange(photo.id, e.target.checked)}
                      />
                    </label>
                  </li>
                ))}
              </ul>

              {/* Pagination Controls */}
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  style={{ marginRight: '10px' }}
                >
                  Previous
                </button>
                <p>Page {currentPage} of {totalPages}</p>
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  style={{ marginLeft: '10px' }}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AdminDisplayList;
