import React, { useEffect } from "react";
//eslint-disable-next-line
import { firestore } from "../firebase";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import PhotosDisplay from "./PhotosDisplay";
import "./Home.css";
//eslint-disable-next-line
 
//eslint-disable-next-line

import NavbarNL from "./NavbarNL";

const Home = () => {


  const logoUrl =
    "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Fimage_2024-11-10_175651893.png?alt=media&token=e2bf365f-2774-4ce5-9612-10d95c48e618";

    
  const shedRightUrl =
    "https://firebasestorage.googleapis.com/v0/b/fromtheshed-c9845.appspot.com/o/images%2Fshed-right.jpg?alt=media&token=7ca9b990-0f37-471b-be40-4013d9288b6e";

  useEffect(() => {}, []);

  return (
    <div className="home-container">
      {/* Header with Logo and Title */}
      {/* <HomeHeader /> */}
      <div className="home-header">
        <div className="header-content">
          <h1 className="title">Welcome to the Shed!</h1>
          <img src={logoUrl} alt="Logo" className="logo-img" />
          {/* <img src="./image.png" alt="Logo" className="logo-img" /> */}

          {/* <h4 className="home-subtitle">Specializing in functional home decor products</h4> */}
          <h4 className="title">
            Specializing in functional home decor products
          </h4>
        </div>
        <img src={shedRightUrl} alt="The Shed" className="shed-photo" />
        <NavbarNL />
        {/* <Navbar /> */}
      </div>

      <br></br>

      {/* Home Screen Content */}
      <PhotosDisplay />
    </div>
  );
};

export default Home;
