import React from "react";
import PropTypes from "prop-types"; // Import PropTypes for typing
import { Link } from "react-router-dom";
import './ProductCard.css'; // Ensure this is linked to your component

function ProductCard({ product }) {
  return (
    <div className="product-card">
      <Link to={`/products/${product.id}`} state={product}>
        <div className="product-content">
          <img className="product-image" src={product.imageUrl} alt={product.description} />
          <h3>{product.description}</h3>
          <p className="product-price">Price: ${product.price}</p>
        </div>
        {/* Add "Interested" button */}
        <button className="interested-button">Interested?</button>
      </Link>
    </div>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
};

export default ProductCard;
