import React, { useState, useEffect } from 'react';
import { firestore, collection, getDocs, doc, updateDoc, deleteDoc, addDoc } from '../../firebase'; // Ensure firebase.js is correctly imported
import { ToastContainer, toast } from 'react-toastify';
import './TypeManager.css'; // Add a CSS file for custom styles

function TypeManager() {
  const [productTypes, setProductTypes] = useState([]);
  const [editedProductType, setEditedProductType] = useState(null);
  const [newName, setNewName] = useState('');
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    const fetchProductTypes = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(firestore, 'product-types'));
        const types = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProductTypes(types);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product types:', error);
        setLoading(false);
      }
    };

    fetchProductTypes();
  }, []);

  const handleEdit = (productType) => {
    if (!productType) return; // Ensure there's a valid productType before trying to edit
    setEditedProductType(productType);
    setNewName(productType.name);
    setVisible(productType.visible);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const updates = { name: newName, visible };
    if (!editedProductType) return;

    try {
      const typeRef = doc(firestore, 'product-types', editedProductType.id);
      await updateDoc(typeRef, updates);
      setProductTypes(productTypes.map(pt => pt.id === editedProductType.id ? { ...pt, ...updates } : pt));
      setEditedProductType(null);
      setNewName('');
      setVisible(true);
      toast.success('Product type updated successfully!');
    } catch (error) {
      console.error('Error updating product type:', error);
      toast.error('Failed to update product type.');
    }
  };

  //eslint-disable-next-line
  const handleDelete = async (id) => {
    try {
      const typeRef = doc(firestore, 'product-types', id);
      await deleteDoc(typeRef);
      setProductTypes(productTypes.filter(pt => pt.id !== id));
      toast.success('Product type deleted successfully!');
    } catch (error) {
      console.error('Error deleting product type:', error);
      toast.error('Failed to delete product type.');
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    setAdding(true);

    try {
      await addDoc(collection(firestore, 'product-types'), {
        name: newName,
        visible,
      });

      setNewName('');
      setVisible(true);
      setAdding(false);

      toast.success('Product type added successfully!');
      const querySnapshot = await getDocs(collection(firestore, 'product-types'));
      const updatedProductTypes = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProductTypes(updatedProductTypes);
    } catch (error) {
      console.error('Error adding product type:', error);
      setAdding(false);
      toast.error('Failed to add product type.');
    }
  };

  // Clear form handler
  const handleClearForm = () => {
    setEditedProductType(null);
    setNewName('');
    setVisible(true);
  };

  return (
    <div className="admin-product-type-manager">
      <ToastContainer />
      <h2>Product Types</h2>

      {/* Flexbox Layout */}
      <div className="type-manager-container">
        {/* Product Type List */}
        <div className="product-type-list">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              <select
                value={editedProductType ? editedProductType.id : ''}
                onChange={(e) => handleEdit(productTypes.find(pt => pt.id === e.target.value))}
                style={{ width: '100%', height: '200px' }}
                size={10}
              >
                <option value="">-- Add New Product Type --</option>
                {productTypes.map((productType) => (
                  <option key={productType.id} value={productType.id}>
                    {productType.name}
                  </option>
                ))}
              </select>

              {editedProductType && (
                <div className="edit-product-type-form">
                  <h3>Edit Product Type</h3>
                  <form onSubmit={handleUpdate}>
                    <div>
                      <label htmlFor="new-name">New Name:</label>
                      <input
                        type="text"
                        id="new-name"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="visible">Visible:</label>
                      <input
                        type="checkbox"
                        id="visible"
                        checked={visible}
                        onChange={(e) => setVisible(e.target.checked)}
                      />
                    </div>
                    <button type="submit">Update</button>
                    <button type="button" onClick={handleClearForm}>Clear</button>
                  </form>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Add New Product Type */}
        <div className="add-product-type-form">
          <h3>Add New Product Type</h3>
          <form onSubmit={handleAdd}>
            <div>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="visible">Visible:</label>
              <input
                type="checkbox"
                id="visible"
                checked={visible}
                onChange={(e) => setVisible(e.target.checked)}
              />
            </div>
            <button type="submit" disabled={adding}>
              {adding ? 'Adding...' : 'Add Product Type'}
            </button>
            <button type="button" onClick={handleClearForm}>Clear Form</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TypeManager;
